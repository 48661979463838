.navbar-brand img {
  height: 45px; /* Default height */
  max-width: 100%; /* Ensures the image does not overflow */
  width: auto; /* Maintains aspect ratio */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  /* Tablets and smaller screens */
  .navbar-brand img {
    height: 40px;
  }
}

@media (max-width: 576px) {
  /* Mobile screens */
  .navbar-brand img {
    height: 35px;
  }
}

@media (max-width: 400px) {
  /* Very small devices */
  .navbar-brand img {
    height: 25px;
  }
}
/* @media (max-width: 991.98px) {
  .ftco-navbar-light {
    background: transparent !important;
    top: 0;
    position: relative;
  }
}
.ftco-navbar-light .navbar-nav > .nav-item > .nav-link:hover {
  color: black;
}
.ftco-navbar-light .navbar-nav > .nav-item > .nav-link {
  color: black;
} */
/* .sticky-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #343a40; 
  transition: all 0.3s ease-in-out;
} */
/* .navbar {
  background-color: transparent;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.navbar.scrolled {
  background-color: white !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.navbar.scrolled .nav-link {
  color: #333 !important;
}

.navbar.scrolled .navbar-brand img {
  filter: invert(1); 
} */

/* .navbar {
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
} */

/* .navbar {
  background-color: transparent !important;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
} */
.navbar {
  background-color: white !important;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.scrolled {
  background-color: white !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.scrolled .nav-link {
  color: #333 !important;
}
.navbar .nav-item {
  margin-left: 15px;
}
/* .scrolled .navbar-brand img {
  filter: invert(1);
} */
@media (max-width: 768px) {
  .navbar {
    background-color: #333 !important;
  }

  .nav-link {
    color: white !important; /* Ensures visibility on dark background */
  }
  .scrolled {
    background-color: white !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}
.dropdown-menu {
  background-color: white;
  border-radius: 5px;
  display: block;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.dropdown-menu.show {
  opacity: 1;
  transform: translateY(0);
}

.dropdown-item {
  color: #333 !important;
}

.dropdown-item:hover {
  background-color: #f8f9fa !important;
}
.navbar-nav .nav-item.active .nav-link {
  color: orange !important;
  font-weight: bold;
}
/* .navbar-dark .navbar-nav .nav-link {
  color: #ffff;
} */
.navbar-dark .navbar-nav .nav-link {
  color: black;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: #f86f2d;
}
/* Change Owl Carousel Dots Color to Black */
.owl-dots .owl-dot span {
  background: black !important;
}

.owl-dots .owl-dot.active span {
  background: black !important;
}
.blog-image-container {
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}

.blog-image-container img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.blog-image-container img:hover {
  transform: scale(1.05);
}

.card {
  border-radius: 12px;
  overflow: hidden;
}

.card-body {
  background: #f8f9fa;
}

.blog-detail {
  font-size: 14px;
  font-weight: 500;
}
